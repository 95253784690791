<template>
  <div>
    <div style="color: #889aa4; padding: 10px">
      客户ID：
      <el-select
        v-model="value"
        size="mini"
        placeholder="请选择客户"
        filterable
      >
        <el-option
          v-for="item in users"
          :key="item.id"
          filterable
          :label="`${item.hd_code}>${item.username}`"
          :value="item.id"
        >
        </el-option>
      </el-select>

      <el-button
        style="margin-left: 10px"
        type="success"
        size="mini"
        @click="importShow"
        >导入产品</el-button
      >
      <el-button
        style="margin-left: 10px"
        type="danger"
        size="mini"
        @click="delSel"
        >删除选中商品</el-button
      >

      <div style="margin-top: 5px">
        规则：
        <el-select
          v-model="selCreateOrderType"
          placeholder="请选择生成订单的方式"
          size="mini"
        >
          <el-option
            v-for="item in createOrderType"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-button
          style="margin-left: 10px"
          type="primary"
          size="mini"
          @click="creatOrder"
          >提交订单</el-button
        >
        <el-button @click="showGoodsList">生成商品信息</el-button>
      </div>
    </div>

    <div
      style="
        color: #889aa4;
        padding: 10px;
        border-top: 1px solid #ccc;
        color: forestgreen;
      "
      v-if="cartTotal.list > 0"
    >
      条数：<span style="margin-right: 30px; color: orangered">{{
        cartTotal.list
      }}</span>
      本页商品总数：<span style="margin-right: 30px; color: orangered">{{
        cartTotal.goodsNum
      }}</span>
      总价：<span style="margin-right: 30px; color: orangered">
        {{ cartTotal.totalPrice }}</span
      >
    </div>

    <div style="margin-left: 10px">
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        @selection-change="handleSelectionChange"
        border
      >
        <el-table-column type="selection" width="55"> </el-table-column>

        <el-table-column prop="id" label="序号" width="50" align="center">
        </el-table-column>

        <el-table-column label="图片" width="120" align="center">
          <template slot-scope="scope">
            <img
              height="60"
              :src="`http://hwimg.xmvogue.com/thumb/${scope.row.item_no}.jpg?x-oss-process=style/440`"
              class="mr-3"
              alt="..."
            />
          </template>
        </el-table-column>

        <el-table-column prop="item_no" label="SKU" width="150" align="center">
        </el-table-column>
        <el-table-column prop="item_name" label="产品名称" align="left">
        </el-table-column>
        <el-table-column prop="price" label="价格" width="200" align="center">
          <template slot-scope="scope">
            {{ scope.row.price.toFixed(2) }}
          </template>
        </el-table-column>
        <el-table-column prop="num" label="数量" width="200" align="center">
          <template slot-scope="scope">
            <el-input-number
              v-model="scope.row.num"
              :step="scope.row.spe"
              size="mini"
              @change="
                (currentValue, oldValue) =>
                  handleChange(currentValue, oldValue, scope.row)
              "
            >
            </el-input-number>
          </template>
        </el-table-column>
        <el-table-column prop="total" label="小计" width="200" align="center">
          <template slot-scope="scope">
            {{ scope.row.total.toFixed(2) }}
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div style="text-align: center">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pages.currentPage"
        :page-sizes="[1, 20, 50, 100, 500, 800]"
        :page-size="pages.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pages.total"
      >
      </el-pagination>
    </div>

    <el-drawer
      title="导入产品数据"
      :visible.sync="drawer"
      :direction="direction"
      :before-close="handleClose"
      :wrapperClosable="false"
    >
      <div style="width: 90%; margin: 0 auto">
        示例:
        <div style="font-size: 12px; color: #889aa4">
          <pre>
SKU          数量
6941924321540,10
6941924321557,10
6946957080273,10
</pre>
        </div>
        <el-input
          type="textarea"
          placeholder="输入产品code"
          rows="8"
          v-model="goodsCodeS"
        ></el-input>

        <div v-show="importGoodsDataSuccess.length > 0">
          <p>成功信息:</p>
          <div
            style="
              border: 1px solid #c0c0c0;
              height: 230px;
              overflow: auto;
              padding: 5px;
            "
          >
            <el-alert
              v-for="(item,index) in importGoodsDataSuccess"
              :title="item"
              type="success"
              style="margin-top: 5px"
              :key="index"
            >
            </el-alert>
          </div>
        </div>
        <el-button type="success" style="margin: 10px" @click="implodeSubmit">提交</el-button>
      </div>
      <hr />
      <div v-show="importGoodsDataError.length > 0">
        <p style="width: 90%; margin: 0 auto; color: red">错误信息:</p>
        <div
          style="
            width: 90%;
            margin: 0 auto;
            border: 1px solid #c0c0c0;
            height: 230px;
            overflow: auto;
            padding: 5px;
          "
        >
          <el-alert
            v-for="(item, index) in importGoodsDataError"
            :title="item"
            type="error"
            style="margin-top: 5px"
            :key="index"
          >
          </el-alert>
        </div>
      </div>
    </el-drawer>

    <!-- 显示商品 -->
    <el-dialog
      title="购物车信息列表"
      :visible.sync="showCartGoodsInfo.show"
      width="30%"
      :before-close="handleClose"
    >
      <div>
        <textarea
          rows="10"
          cols="74"
          v-model="showCartGoodsInfo.goodsList"
        ></textarea>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="showCartGoodsInfo.show = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showCartGoodsInfo: {
        show: false,
        goodsList: [],
      },
      users: [],
      value: "",
      tableData: [],
      multipleSelection: [],
      drawer: false,
      direction: "rtl",
      goodsCodeS: "",
      importGoodsDataError: [],
      importGoodsDataSuccess: [],
      selCreateOrderType: "2",
      createOrderType: [
        {
          value: "0",
          label: "按小类生成",
        },
        {
          value: "1",
          label: "按中类生成",
        },
        {
          value: "2",
          label: "按大类生成",
        },
      ],
      cartTotal: {
        list: 0,
        goodsNum: 0,
        totalPrice: 0,
      },
      pages: {
        currentPage: 1,
        size: 20,
        total: 0,
      },
    };
  },
  watch: {
    value() {
      this.getData();
    },
  },
  created() {
    this.getUserByMan();
  },
  methods: {
    //翻页部分 begin
    handleSizeChange(val) {
      this.pages.size = val;
      this.getData();
    },
    handleCurrentChange(val) {
      this.pages.currentPage = val;
      this.getData();
    },
    //翻页部分 end
    //获取参数列表
    getData() {
      let selUserId = this.value;
      let page = this.pages.currentPage < 1 ? 1 : this.pages.currentPage;
      let queryData = {
        page: page,
        page_size: this.pages.size,
      };
      this.$axios
        .get(`/a1/cart/${selUserId}`, {
          params: queryData,
        })
        .then((res) => {
          let respData = res.data.data;
          this.tableData = respData.data;

          let list = respData.total;
          let goodsNum = 0; //当前页
          let totalPrice = respData.price;
          for (let i = 0; i < this.tableData.length; i++) {
            //list++;
            goodsNum += this.tableData[i].num;
            //totalPrice += this.tableData[i].total;
          }
          this.cartTotal.list = list;
          this.cartTotal.goodsNum = goodsNum;
          this.cartTotal.totalPrice = totalPrice.toFixed(2);
          //翻页赋值
          this.pages.currentPage = respData.page;
          this.pages.total = respData.total;
          this.pages.size = respData.page_size;
        });
    },
    //显示购物车详情
    showGoodsList() {
      if (this.tableData.length > 0) {
        let showText = "";
        for (let i = 0; i < this.tableData.length; i++) {
          showText +=
            this.tableData[i].item_no + "," + this.tableData[i].num + "\n";
        }
        this.showCartGoodsInfo.goodsList = showText;
        this.showCartGoodsInfo.show = true;
      } else {
        this.$message.error("购物车没有商品");
      }
    },
    //修改购物车产品
    handleChange(currentValue, oldValue, rowData) {
      //rowData.spe

      if (currentValue % rowData.spe != 0) {
        this.$message.error("请按规格倍数更改，当前商品规格为:" + rowData.spe);
        //上下文绑定
        this.$nextTick(() => {
          rowData.num = oldValue;
        });
      } else {
        let adminUser = JSON.parse(window.sessionStorage.getItem("user"));
        let upData = {
          item_no: rowData.item_no,
          number: currentValue,
        };
        //修改参数
        this.$axios.put("/a1/cart/" + this.value, upData).then((res) => {
          this.$message.success("数量修改完成");
          this.getData();
        });
      }
    },
    creatOrder() {
      if (this.multipleSelection.length > 0) {
        this.$confirm("你确定将选中的商品生成订单吗？")
          .then((_) => {
            let adminUser = JSON.parse(window.sessionStorage.getItem("user"));
            //1.组成提交订单的数据
            let goodsCodes = [];
            for (let i = 0; i < this.multipleSelection.length; i++) {
              goodsCodes.push(this.multipleSelection[i].item_no);
            }

            //2.提交数据到后台生成订单
            let postData = {
              goods: [],
              order_pack_type: 2,
            };
            postData.goods = goodsCodes;
            postData.order_pack_type = parseInt(this.selCreateOrderType);
            //3.提示去订单中心查看数据

            this.$axios
              .post("/a1/cart/creatOrder/" + this.value, postData)
              .then((res) => {
                this.$message.success("订单生成成功");
                this.getData();
              });
          })
          .catch((_) => {
            this.$message.warning("取消生成订单");
          });
      } else {
        this.$message.warning("请选择要提交的产品");
      }
    },
    //删除选择的商品
    delSel() {
      if (this.multipleSelection.length > 0) {
        let adminUser = JSON.parse(window.sessionStorage.getItem("user"));
        for (let i = 0; i < this.multipleSelection.length; i++) {
          let postItem = {
            item_no: this.multipleSelection[i].item_no,
          };

          this.$axios
            .delete("/a1/cart/" + this.value, {
              data: postItem,
            })
            .then((res) => {
              //this.CanGetData.push(1)
            });
        }
        this.$message.success("操作完成");
        this.getData();
      } else {
        this.$message.warning("请选择你要删除的商品");
      }
    },
    //导入购物车
    implodeSubmit() {
      if (this.goodsCodeS != "") {
        // this.__intReload();
        let postList = [];
        let list = this.goodsCodeS.split("\n");
        let adminUser = JSON.parse(window.sessionStorage.getItem("user"));
        let postSkuList = []; //需要提交的sku列表
        let postDataList = []; //准备导入的sku数量
        for (let i = 0; i < list.length; i++) {
          let theGoodsArr = list[i].split(",");
          postSkuList.push(theGoodsArr[0]);
          postDataList[theGoodsArr[0]] = theGoodsArr[1];
        }
        //先查询到对应的商品信息
        let querySkuList = {
          sku: postSkuList,
        };
        this.$axios.post("/a1/goods/inSku", querySkuList).then((res) => {
          let resData = res.data.data;
          if (resData.length > 0) {
            this.importGoodsDataError = [];
            this.importGoodsDataSuccess = [];
            for (let j = 0; j < resData.length; j++) {
              let theGoods = resData[j];
              if (postDataList[theGoods.item_no] % theGoods.purchase_spec !== 0) {
                this.importGoodsDataError.push(
                  "【" +
                    theGoods.item_no +
                    "】购物车数量不匹配，该商品规格为：" +
                    theGoods.purchase_spec
                );
              } else {
                let postData = {
                  item_no: theGoods.item_no,
                  number: parseInt(postDataList[theGoods.item_no]),
                  spe: theGoods.purchase_spec,
                };
                //提交购物车
                this.$axios
                  .post("/a1/cart/" + this.value, postData)
                  .then(() => {
                    this.importGoodsDataSuccess.push(
                      "【" + theGoods.item_no + "】导入成功"
                    );
                  })
                  .catch((err) => {
                    this.importGoodsDataError.push(
                      "【" + theGoods.item_no + "】" + err.response.data.message
                    );
                  }).finally(()=>{
                      this.getData()
                  });
              }
            }
          } else {
            this.$message.error("未找到输入的商品条码信息");
          }
        });
      } else {
        this.$message.error("请输入商品条码及数据");
      }
    },
    //导入显示
    importShow() {
      this.importGoodsDataError = [];
      this.importGoodsDataSuccess = [];
      if (this.value > 0) {
        this.drawer = true;
      } else {
        this.$message.warning("请先选择操作的客户");
      }
    },

    //获取到客户列表
    getUserByMan() {
      let adminUser = JSON.parse(window.sessionStorage.getItem("user"));
      this.$axios
        .get("/a1/user", {
          params: {
            cs_id: adminUser.admin_info.id,
            page_size: 500,
            page: 1,
          },
        })
        .then((res) => {
          this.users = res.data.data.data;
        });
    },
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    //关闭抽屉效果:导入抽屉
    handleClose(done) {
      done();
      // this.$confirm("确认关闭？")
      // 	.then((_) => {
      // 		done();
      // 		this.getData();
      // 	})
      // 	.catch((_) => {});
    },
  },
};
</script>

<style></style>
